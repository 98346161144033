import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { useLocalStorage } from '../hooks/useLocalStorage';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Navbar({ location, hasEventEnded }) {
  const [eventEnded] = useLocalStorage('hasEventEnded', '');
  const [activeSection, setActiveSection] = useState(null);

  const navigation = [
    { name: 'Home', href: '/#top', sectionId: 'top', current: location && location.pathname == ('/') && activeSection === null || activeSection === 'top', useHashLink: true },
    { name: 'Classes', href: '/#schedule', sectionId: 'schedule', current: activeSection === 'schedule', useHashLink: true },
    { name: 'Ask the Experts', href: '/#ask-the-experts', sectionId: 'ask-the-experts', current: activeSection === 'ask-the-experts', useHashLink: true },
    { name: 'Register', href: '/registration/', sectionId: 'register', current: location && location.pathname.includes('registration') }
  ];

  if (eventEnded || hasEventEnded) {
    navigation.pop();
  }

  const handleScroll = () => {
    if (location && location.pathname.includes('registration')) return;

    const sections = ['top', 'schedule', 'ask-the-experts'];
    let currentSection = null;

    for (let section of sections) {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentSection = section;
          break;
        }
      }
    }
    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <div className="bg-brand-500 sticky top-0 z-20">
      <div className="max-w-7xl relative mx-auto px-4 md:px-6">
        <nav className="flex space-x-2 lg:space-x-8 justify-between md:justify-start" aria-label="Global">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                item.current ? 'text-white border-white font-semibold' : 'text-white hover:border-white',
                'border-b-4 border-transparent py-4 px-3 inline-flex items-center text-sm md:text-base font-medium focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-brand-500'
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
